@import 'src/assets/styles';

.country-select {
  &__label {
    display: block;
    margin-bottom: 8px;
    @include base-text($publicSansSemiBold, 14px, 20px, map-get($text, 600));
  }

  .react-select-option {
    display: flex;
    @include base-text($publicSansSemiBold, 16px, 20px, map-get($text, 800));

    &_selected {
      background-color: map-get($primary, 100);
    }

    &:hover {
      cursor: pointer;
    }

    &-icon {
      height: 20px;
    }

    &-value {
      margin-left: 6px;
    }

    &-label {
      flex: 0 1 25%;

      @media only screen and (min-width: get-mq-min-width($tablet)) {
        flex: 0 1 15%;
      }
    }
  }
}
