.select {
  &__virtualized-menu-list {
    padding: 10px;
    box-sizing: border-box;
    min-height: 56px;

    & > div {
      position: relative;
    }
  }

  &__option {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
