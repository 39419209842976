@import 'colors';

$h1-font-size: 50px;
$h1-line-height: 61px;
$h2-font-size: 30px;
$h2-line-height: 37px;
$h3-font-size: 24px;
$h3-line-height: 29px;
$h4-font-size: 22px;
$h4-line-height: 24px;
$h5-font-size: 20px;
$h5-line-height: 24px;
$h6-font-size: 18px;
$h6-line-height: 22px;

$body-font-size: 16px;
$body-line-height: 20px;

$fonts-opacity: 0.46;

$montserrat: 'Montserrat';
$acumin: 'Acumin Pro';

@mixin base-text($font-family, $font-size, $line-height, $color: $black) {
  font-family: $font-family;
  font-size: $font-size;
  line-height: $line-height;
  color: $color;
}

$publicSansNormal: 'PublicSansRegular';
$publicSansSemiBold: 'PublicSansSemiBold';
$publicSansBold: 'PublicSansBold';

$workSansBold: 'WorkSansBold';
