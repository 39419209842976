@import 'fonts';

$mobileSmall: 'mobileSmall';
$mobileRegular: 'mobileRegular';
$mobileBig: 'mobileBig';
$tablet: 'tablet';
$desktopSmall: 'desktopSmall';
$desktopRegular: 'desktopRegular';

@font-face {
  font-family: 'WorkSansBold';
  src: url('../fonts/WorkSans-Bold.ttf') format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'PublicSansRegular';
  src: url('../fonts/PublicSans-Regular.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'PublicSansSemiBold';
  src: url('../fonts/PublicSans-SemiBold.ttf') format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'PublicSansBold';
  src: url('../fonts/PublicSans-Bold.ttf') format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: $montserrat;
  src: url('../fonts/Montserrat-Light.ttf');
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: $montserrat;
  src: url('../fonts/Montserrat-Regular.ttf');
  font-style: normal;
  font-weight: bold;
}

@font-face {
  font-family: $montserrat;
  src: url('../fonts/Montserrat-Medium.ttf');
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: $montserrat;
  src: url('../fonts/Montserrat-Bold.ttf');
  font-style: normal;
  font-weight: 800;
}

@font-face {
  font-family: $acumin;
  src: url('../fonts/Acumin-RPro.otf');
  font-style: normal;
}

@font-face {
  font-family: 'Public Sans';
  src: url('../fonts/PublicSans-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Public Sans';
  src: url('../fonts/PublicSans-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Public Sans';
  src: url('../fonts/PublicSans-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Public Sans';
  src: url('../fonts/PublicSans-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Public Sans';
  src: url('../fonts/PublicSans-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

html {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
}

body {
  margin: 0;
  padding: 0;
  @include base-text($acumin, $body-font-size, $body-line-height);
}

#root {
  @include base-text($acumin, $body-font-size, $body-line-height);
}

.subheader {
  @include base-text($acumin, $body-font-size, $body-line-height);
  color: $white;
}

h1 {
  @include base-text($montserrat, $h1-font-size, $h1-line-height);
}

h2 {
  @include base-text($montserrat, $h2-font-size, $h2-line-height);
}

h3 {
  @include base-text($montserrat, $h3-font-size, $h3-line-height);
}

h4 {
  @include base-text($montserrat, $h4-font-size, $h4-line-height);
}

h5 {
  @include base-text($acumin, $h5-font-size, $h5-line-height);
}

h6 {
  @include base-text($montserrat, $h6-font-size, $h6-line-height);
}

input {
  &[type='search']::-ms-clear,
  &[type='search']::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
  }

  &[type='search']::-webkit-search-decoration,
  &[type='search']::-webkit-search-cancel-button,
  &[type='search']::-webkit-search-results-button,
  &r[type='search']::-webkit-search-results-decoration {
    -webkit-appearance: none;
    display: none;
  }
}
