@import 'src/assets/styles';

.sector-and-industry-fields {
  display: grid;
  grid-row-gap: 24px;

  &__label {
    display: block;
    margin-bottom: 8px;
    @include base-text($publicSansSemiBold, 14px, 20px, map-get($text, 600));
  }
}
