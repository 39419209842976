@import 'src/assets/styles';

.phone-select {
  &__label {
    @include base-text($publicSansSemiBold, 14px, 20px, map-get($text, 600));
  }

  &__phone {
    position: relative;
    display: flex;

    > div {
      &:last-of-type {
        width: 100%;

        .form-input__wrapper {
          > input {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            border-left: none;
          }
        }
      }
    }
  }

  .react-select-option {
    display: flex;
    @include base-text($publicSansSemiBold, 16px, 20px, map-get($text, 800));

    &_selected {
      background-color: map-get($primary, 100);
    }

    &:hover {
      cursor: pointer;
    }

    &-icon {
      height: 20px;
    }

    &-value {
      margin-left: 6px;
    }

    &-label {
      flex: 0 1 25%;

      @media only screen and (min-width: get-mq-min-width($tablet)) {
        flex: 0 1 15%;
      }
    }
  }
}
