.form-container {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 1rem 3rem;
  border-radius: 20px;
  border: 1px solid #d1d2d4;
  width: 30rem;
  max-width: 90%;
  font-size: 10px;

  &__forgetPassword {
    margin-top: 0.3rem;
    color: #317f81;
    font-size: 10px !important;
    text-decoration: underline;
    font-family: 'PublicSansRegular';
    text-align: end;
    cursor: pointer;
    text-underline-offset: 2px;
  }
  &__submit {
    margin-top: 2rem;
    min-width: 38% !important;
    font-size: 14px !important;
    align-self: center;
    font-family: 'Public Sans';
  }
}
