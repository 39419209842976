#click-wrap-container {
  margin-top: 1.5rem;
  text-align: start !important;
}

.ps-contract {
  padding-bottom: 0 !important;
}

.ps-contract-label a {
  text-decoration: underline;
  color: rgb(0, 0, 238);
}
